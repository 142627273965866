import React, { useState } from 'react'
import { Row, Col, Card, Button } from 'reactstrap'
function ChargerCalibration(props) {
  const CommandCenter = props.CommandCenter
  const integerTo4ByteArray = props.integerTo4ByteArray
  const integerTo2ByteArray = props.integerTo2ByteArray
  const chargerDataRecieved = props.chargerDataRecieved
  const byteArrayToInt2 = props.byteArrayToInt2
  const [calCurrent, setCalCurrent] = useState('')
  const [calVoltage, setCalVoltage] = useState('')
  const dataObject = {
    currentRef: '',
    voltageRef: '',
    current: "",
    voltage: '',
  }
  function sendCalCurrent() {
    const canId = integerTo4ByteArray(285021514)
    const messageId = 1
    const dataArray = [66, ...calCurrent, 0, 0, 0, 0, 0]
    console.log(canId, messageId, dataArray)
    // CommandCenter(canId, messageId, dataArray)
  }
  function sendCalVoltage() {
    const canId = integerTo4ByteArray(285021514)
    const messageId = 1
    const dataArray = [65, ...calVoltage, 0, 0, 0, 0, 0]
    CommandCenter(canId, messageId, dataArray)
  }
  if (chargerDataRecieved) {
    dataObject.currentRef = byteArrayToInt2([chargerDataRecieved[9], chargerDataRecieved[10]]) / 100
    dataObject.voltageRef = byteArrayToInt2([chargerDataRecieved[11], chargerDataRecieved[12]]) / 100
    dataObject.current = byteArrayToInt2([chargerDataRecieved[13], chargerDataRecieved[14]]) / 100
    dataObject.voltage = byteArrayToInt2([chargerDataRecieved[15], chargerDataRecieved[16]]) / 100
  }
  return (
    <div>
      <Row>
        <Col xs="12" sm="12" md="4" lg="4" xl="4">
          <Card className="hero-cards">
            <div className="card-head">Current Ref</div>
            <div className="card-foot">
              {dataObject.currentRef}
            </div>
          </Card>
        </Col>
        <Col xs="12" sm="12" md="4" lg="4" xl="4">
          <Card className="hero-cards">
            <div className="card-head">Voltage Ref</div>
            <div className="card-foot">
              {dataObject.voltageRef}
            </div>
          </Card>
        </Col>
        <Col xs="12" sm="12" md="4" lg="4" xl="4">
          <Card className="hero-cards">
            <div className="card-head">Current</div>
            <div className="card-foot">
              {dataObject.current} A
            </div>
          </Card>
        </Col>
        <Col xs="12" sm="12" md="4" lg="4" xl="4">
          <Card className="hero-cards">
            <div className="card-head">Voltage</div>
            <div className="card-foot">
              {dataObject.voltage} V
            </div>
          </Card>
        </Col>
      </Row>
      <strong>Input Current (A): </strong>
      <input type='text' placeholder='Current Here' onChange={(e) => setCalCurrent(integerTo2ByteArray(e.target.value * 100))}></input>
      <Button onClick={sendCalCurrent}>Set</Button>
      <br />
      <strong>Input Voltage (V): </strong>
      <input type='text' placeholder='Current Voltage' onChange={(e) => setCalVoltage(integerTo2ByteArray(e.target.value * 100))}></input>
      <Button onClick={sendCalVoltage}>Set</Button>
    </div>
  )
}

export default ChargerCalibration