import "components/Tabs/Tabs.scss";

import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import Analytics from "views/Analytics";
import Home from "views/Home";
import React from "react";
import ShowConfiguration from "components/BMSConfiguration/NewConfiguration";
import SingleMapPro from "components/MapPro.js/SingleMapPro";
import Parallel from "components/Parallel/Parallel";
import ChargerData from "components/ChargerData/ChargerData";
import ParallelTab from "components/Parallel/ParallelTab";
import ChargerTab from "components/ChargerData/ChargerTab";
import LogsTab from "components/Logs/LogsTab";
import LifeTimeAnalysis from "components/Analysis/LifeTimeAnalysis";
import AnalysisTab from "components/Analysis/AnalysisTab";

// reactstrap components

// core components

function Tabloid(props) {
  const [iconPills, setIconPills] = React.useState("1");
  const [showContent, setShowContent] = React.useState(<div>
    <Home homeBmsValues={props.homeBmsValues} moduleNumber={props.moduleNumber}/>
    {/* <AnalysisTab /> */}
  </div>
  )
  return (
    <>
      <div className="section section-tabs">
        <Card style={{ marginRight: "10px" }}>
          <Nav className="justify-content-center" role="tablist" tabs>
            <NavItem>
              <NavLink
                className={iconPills === "1" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills('1')
                  setShowContent(
                    <Home homeBmsValues={props.homeBmsValues} moduleNumber={props.moduleNumber}/>
                  );
                }}
              >
                <i class="fa fa-home" aria-hidden="true"></i>&ensp;{" "}
                <strong>Home</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "2" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills('2')
                  setShowContent(
                    <Analytics />
                  );
                }}
              >
                <i className="nc-icon nc-chart-bar-32" />
                &ensp; <strong>Analytics</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "3" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("3");
                  setShowContent(
                    <SingleMapPro
                      handleMapCoordinates={props.handleMapCoordinates}
                      locationDataLoaded={props.locationDataLoaded}
                      setLocationDataLoaded={props.setLocationDataLoaded}
                      setLocationDataLoadedFirst={props.setLocationDataLoadedFirst}
                    />
                  );
                }}
              >
                <i className="nc-icon nc-pin-3" />
                &ensp; <strong>Track</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "4" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("4");
                  setShowContent(
                    <ShowConfiguration />
                  );
                }}
              >
                <i class="fas fa-cogs"></i>
                &ensp; <strong>BMS Configuration</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "5" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("5");
                  setShowContent(
                    <LogsTab />
                  );
                }}
              >
                 <i class="fab fa-readme"></i>
                &ensp; <strong>Logs</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "6" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("6");
                  setShowContent(
                    <ChargerTab />
                  );
                }}
              >
                <i class="fas fa-digital-tachograph"></i>
                &ensp; <strong>Charger Data</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={iconPills === "7" ? "active" : ""}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setIconPills("7");
                  setShowContent(
                      window.localStorage.getItem('role') === 'admin' ?
                      <AnalysisTab />
                      : <div>
                      <strong>For Premium Users Only!</strong>
                      <br />
                      <span>Upgrade Your account to view this tab.</span>
                    </div>
                  );
                }}
              >
                <i class="fas fa-chart-pie"></i>
                &ensp; <strong>Analysis</strong>
                {/* <span style={{fontSize:"smaller"}}>(Beta)</span> */}
              </NavLink>
            </NavItem>
          </Nav>
          <div style={{ textAlign: 'center' }}>
            {showContent}
          </div>
        </Card>
      </div>
    </>
  );
}

export default Tabloid;
