import { Bar, Line } from "react-chartjs-2";
import React, { useState, useEffect } from "react";

import Badge from "reactstrap/lib/Badge";
import { Card } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { useContext } from "react";
import { valueDataMContext } from "views/Dashboard";

function Temperature(props) {
  const moduleNumber = props.moduleNumber
  let dividedArray = []
  const [selectedModule, setSelectedModule] = [props.selectedModule, props.setSelectedModule]
  function divideArray(arr, totalChunks) {
    let result = [];
    let chunkSize = Math.ceil(arr.length / totalChunks);
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }
  const showElementArray = Array(moduleNumber).fill(0)
  let valueDataItem = props.valueDataItem
  let valueDataM = useContext(valueDataMContext)
  if (valueDataItem) {
    if (valueDataM) {
      let tempValues = [];
      let tempLabels = [];
      let tempColor = [];
      let cellTempData


      if (moduleNumber > 1) {

        dividedArray = divideArray(valueDataM.ext[0].cellTempData.tempValues.cellTemp, moduleNumber)
        if (selectedModule !== 'All')
          cellTempData = dividedArray[selectedModule]
        else
          cellTempData = valueDataM.ext[0].cellTempData.tempValues.cellTemp
      }
      else {
        cellTempData = valueDataM.ext[0].cellTempData.tempValues.cellTemp
      }
      let icTempData = valueDataM.ext[0].cellTempData.tempValues.icTemp
      let pcbTempData = valueDataM.ext[0].cellTempData.tempValues.pcbTemp
      let heatsinkTempData = valueDataM.ext[0].cellTempData.tempValues.heatsink
      let tempMax = Math.max(...valueDataM.ext[0].cellTempData.tempValues.cellTemp)
      let tempMin = Math.min(...valueDataM.ext[0].cellTempData.tempValues.cellTemp)
      for (let i = 0; i < cellTempData.length; i++) {
        tempValues.push(cellTempData[i]);
        tempLabels[i] = selectedModule === 'All' ? "CT"+(i + 1) : "CT" + (selectedModule * cellTempData.length + i + 1)
        if (cellTempData[i] === tempMax) tempColor[i] = "#b8b209";
        else if (cellTempData[i] === tempMin) tempColor[i] = "#078da8";
        else tempColor[i] = "rgba(10, 255, 10, 1)";
      }
      for (let i = 0; i < icTempData.length; i++) {
        tempValues.push(icTempData[i]);
        tempLabels.push("ICT" + (i + 1));
        tempColor.push("rgba(10, 255, 255, 1)");
      }
      for (let i = 0; i < pcbTempData.length; i++) {
        tempValues.push(pcbTempData[i]);
        tempLabels.push("PCBT" + (i + 1));
        tempColor.push("rgba(10, 255, 255, 1)");
      }
      const TempChart = {
        data: () => {
          return {
            labels: tempLabels,
            datasets: [
              {
                label: "Temperature (°C)",
                data: tempValues,
                backgroundColor: tempColor,
                borderColor: [],
                borderWidth: 0,
              },
            ],
          };
        },

        options: {
          legend: {
            display: false,
            position: "top",
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
      return (
        <div>
          <Card style={{ padding: "5px" }}>
            <div style={{ textAlign: "center" }}>
              <div className="card-head">Temperature</div>
              <Badge
                style={{
                  backgroundColor: "#078da8",
                  color: "white",

                  margin: "0 10px",
                }}
              >
                Min Cell Temp:{" "}
                {tempMin} °C
              </Badge>
              <Badge
                style={{
                  backgroundColor: "#b8b209",
                  color: "white",
                  margin: "0 10px",
                }}
              >
                Max Cell Temp:{" "}
                {tempMax} °C
              </Badge>
            </div>
            <Bar data={TempChart.data} options={TempChart.options} />
          </Card>
        </div>
      );
    }
    let tempValues = [];
    let tempLabels = [];
    let tempColor = [];
    let cellTempData
    let icTempData
    let pcbTempData
    let heatsinkTempData
    let tempMax
    let tempMin
    if (props.dataFrom === "BLEView") {
      cellTempData = valueDataItem.cellTempArray
      icTempData = valueDataItem.icTempArray
      pcbTempData = valueDataItem.pduTempArray
      tempMax = Math.max(parseInt(valueDataItem.cellTempArray))
      tempMin = Math.min(parseInt(valueDataItem.cellTempArray))
    }
    else {
      if (moduleNumber > 1) {
        dividedArray = divideArray(JSON.parse(valueDataItem.cellTemp), moduleNumber)
        if (selectedModule !== 'All')
          cellTempData = dividedArray[selectedModule]
        else
          cellTempData = JSON.parse(valueDataItem.cellTemp);
      }
      else {
        cellTempData = JSON.parse(valueDataItem.cellTemp);
      }

      icTempData = JSON.parse(valueDataItem.icTemp);
      pcbTempData = JSON.parse(valueDataItem.pcbTemp);
      heatsinkTempData = JSON.parse(valueDataItem.heatsink);
      tempMax = Math.max(...JSON.parse(valueDataItem.cellTemp));
      tempMin = Math.min(...JSON.parse(valueDataItem.cellTemp));

    }
    for (let i = 0; i < cellTempData.length; i++) {
      tempValues.push(cellTempData[i]);
      tempLabels[i] = selectedModule === 'All' ?"CT"+ (i + 1) : "CT" + (selectedModule * cellTempData.length + i + 1)
      if (cellTempData[i] === tempMax) tempColor[i] = "#b8b209";
      else if (cellTempData[i] === tempMin) tempColor[i] = "#078da8";
      else tempColor[i] = "rgba(10, 255, 10, 1)";
    }
    for (let i = 0; i < icTempData.length; i++) {
      tempValues.push(icTempData[i]);
      tempLabels.push("ICT" + (i + 1));
      tempColor.push("rgba(10, 255, 255, 1)");
    }
    for (let i = 0; i < pcbTempData.length; i++) {
      tempValues.push(pcbTempData[i]);
      tempLabels.push("PCBT" + (i + 1));
      tempColor.push("rgba(10, 255, 255, 1)");
    }

    const TempChart = {
      data: () => {
        return {
          labels: tempLabels,
          datasets: [
            {
              label: "Temperature (°C)",
              data: tempValues,
              backgroundColor: tempColor,
              borderColor: [],
              borderWidth: 0,
            },
          ],
        };
      },

      options: {
        legend: {
          display: false,
          position: "top",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    return (
      <div>
        <Card style={{ padding: "5px" }}>
          <div style={{ textAlign: "center" }}>
            <div className="card-head">Temperature</div>
            <Badge
              style={{
                backgroundColor: "#078da8",
                color: "white",

                margin: "0 10px",
              }}
            >
              Min Cell Temp:{" "}
              {tempMin} °C
            </Badge>
            <Badge
              style={{
                backgroundColor: "#b8b209",
                color: "white",
                margin: "0 10px",
              }}
            >
              Max Cell Temp:{" "}
              {tempMax} °C
            </Badge>
          </div>
          <Bar data={TempChart.data} options={TempChart.options} />
        </Card>
      </div>
    );
  }

  else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}
export default Temperature;
