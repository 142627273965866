import React from 'react'

function IoTLogs(props) {
    const IotBootData = props.iotBootData
    const tableRowStyle = props.tableStyle.tableRowStyle
    const tableHeaderStyle = props.tableStyle.tableHeaderStyle
    const bootUpReasonEnum= ['Application Reset',
    'Firmware Update',
    'Local Reset',
    'Power Up',
    'Remote Reset',
    'Scheduled Reset',
    'Triggered',
    'Unknown',
    'Watchdog']
    if (IotBootData) {
        return (
            <div>
                <table
                    style={{
                        overflow: "scroll",
                        display: "block",
                        height: "95vh",
                    }}
                >
                    <thead style={tableHeaderStyle}>
                        <tr>
                            <th style={tableHeaderStyle}>S. No.</th>
                            <th style={tableHeaderStyle}>Time</th>
                            <th style={tableHeaderStyle}>Firmware Version</th>
                            <th style={tableHeaderStyle}>Hardware Version</th>
                            <th style={tableHeaderStyle}>Boot Up Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        {IotBootData.map((val, i) =>

                            <tr>
                                <td className="text-center" style={tableRowStyle}>
                                    {i + 1}.
                                </td>
                                <td className="text-center" style={tableRowStyle}>
                                    {val.tms.slice(11, 19)}&ensp;
                                    {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                                    {val.tms.slice(0, 4)}
                                </td>
                                <td className="text-center" style={tableRowStyle}>{val.firmwareVersion}
                                </td>
                                <td className="text-center" style={tableRowStyle}>{val.hardwareVersion}
                                </td>
                                <td className="text-center" style={tableRowStyle}>{bootUpReasonEnum[val.bootUpReason]}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
    else return <div>Loading...</div>
}

export default IoTLogs