import {
  Button,
  Card,
  Col,
  Container,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { API_URL } from "variables/general";
import Badge from "reactstrap/lib/Badge";
import React, { useState, useEffect, createContext } from "react";
import Tabloid from "components/Tabs/Tabs";

import {
  AWS_REGION,
  AWS_IOT_ENDPOINT,
  AWS_CUSTOM_AUTH_USERNAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
  AWS_CUSTOM_AUTH_PASSWORD,
} from "./MQTTSettings";
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
const valueDataContext = createContext();
const faultDataContext = createContext();
const valueDataMContext = createContext();
const faultDataMContext = createContext();
const locationDataMContext = createContext();
const packDataContext = createContext();
const configurationDataMContext = createContext();
const bmsIdContext = createContext();
const recordsContext = createContext();
const homeBmsValuesContext = createContext();

function Dashboard(props) {
  // console.log(props);
  const [faultData, setFaultData] = useState("");
  const [valueData, setValueData] = useState("");
  const [faultDataM, setFaultDataM] = useState("");
  const [homeBmsValues, setHomeBmsValues] = useState("");
  const [locationDataM, setLocationDataM] = useState("");
  const [intBattV, setIntBattV] = useState("");
  const [ioaTime, setIoaTime] = useState("");
  const [configurationDataM, setConfigurationDataM] = useState("");
  const [valueDataM, setValueDataM] = useState("");
  const [packData, setPackData] = useState("");
  const [isMqttData, setIsMqttData] = useState(false);
  const [idForAlias, setIdForAlias] = useState("");
  const [alias, setAlias] = useState("");
  const [records, setRecords] = useState("");
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  const sTms = year + '-' + month + '-' + day + 'T00:00:00Z'
  let connection = null;
  async function connect_websocket() {
    return new Promise((resolve, reject) => {
      let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
        .with_clean_session(true)
        .with_client_id(new Date())
        .with_endpoint(AWS_IOT_ENDPOINT)
        .with_custom_authorizer(
          AWS_CUSTOM_AUTH_USERNAME,
          AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
          AWS_CUSTOM_AUTH_PASSWORD,
          AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
        )
        .with_keep_alive_seconds(30)
        .build();

      console.log("Connecting custom authorizer...");
      const client = new mqtt.MqttClient();

      connection = client.new_connection(config);
      connection.on("connect", (session_present) => {
        console.log("Connected", session_present);
        resolve(connection);
      });
      connection.on("interrupt", (error) => {
        console.log(`Connection interrupted: error=${error}`);
      });
      connection.on("resume", (return_code, session_present) => {
        console.log(
          `Resumed: rc: ${return_code} existing session: ${session_present}`
        );
      });
      connection.on("disconnect", () => {
        console.log("Mqtt Disconnected");
      });
      connection.on("error", (error) => {
        reject(error);
      });
      connection.connect();
    });
  }
  const editAlias = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: idForAlias,
        packData: {
          alias: alias,
        },
      }),
    };
    fetch(API_URL + "/bms/bms_devices", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let status = JSON.parse(result).status;
        if (status === true) alert("Update Successful");
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  async function main(val) {
    connect_websocket()
      .then((connection) => {
        connection.subscribe(
          `adtiot/bms1.0/pub/bt/${val}`,
          mqtt.QoS.AtLeastOnce,
          (topic, payload, dup, qos, retain) => {
            const decoder = new TextDecoder("utf8");
            let message = decoder.decode(new Uint8Array(payload));
            console.log('message',message);
            message = JSON.parse(message);
            if (message.isLive)
              getData(message);
          }
        );
        // connection.subscribe(
        //   `jioiot/svc33343232/ABT001/${val}/uc/fwd/events`,
        //   mqtt.QoS.AtLeastOnce,
        //   (topic, payload, dup, qos, retain) => {
        //     const decoder = new TextDecoder("utf8");
        //     let message = decoder.decode(new Uint8Array(payload));
        //     getData(message);
        //   }
        // );
        // .then((publish) => {
        //     return connection.publish('publish.topic', "Hello World!", publish.qos);
        // });
      })
      .catch((reason) => {
        console.log(`Error while connecting: ${reason}`);
      });
  }
  const id = props.history.location.state.id;

  useEffect(() => {
    getPackData();
    getHomeBmsValues();
    handlebmsValues();
    getRecords()
    handlebmsFaults();
    handleIoaData()
    main(id);
    return () => {
      if (connection !== null) connection.disconnect();
    };
  }, []);

  const handlebmsFaults = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: id,
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/bms_faults", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setFaultData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const handleIoaData = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        did: id,
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/bms_ioa", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.bmsIoa.length > 0) {
          setIntBattV(result.data.bmsIoa[result.data.bmsIoa.length - 1].intbattv)
          setIoaTime(result.data.bmsIoa[result.data.bmsIoa.length - 1].tms)
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getHomeBmsValues = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: id,
        latest: true
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/bms_values", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setHomeBmsValues(result.data.data);

      })
      .catch((error) => console.log("error", error));
  };
  const handlebmsValues = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        batteryPackId: id,
        tms: sTms
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/v2/bms_values", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setValueData(result.data);
      })
      .catch((error) => console.log("error", error));
  };
  const getPackData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(API_URL + "/bms/bms_devices?battery_pack_id=" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPackData(result.data);
      })
      .catch((error) => {
        console.log("error", error)
      });
  };
  function getRecords() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({
        batteryPackId: id
      }),
    };

    fetch(API_URL + "/bms/v2/records", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data)
          if (result.data.datatime.length > 0) {
            const dates = result.data.datatime.map(dateStr => {
              const [day, month, year] = dateStr.split('-').map(Number);
              return new Date(2000 + year, month - 1, day);
            });
            dates.sort((a, b) => a - b);
            setRecords(dates)
          }
      })
  }
  const [ignitionStatus, setIgnitionStatus] = useState("");
  const [chargeMosfetStatus, setChargeMosfetStatus] = useState("");
  const [ignitionLoader, setIgnitionLoader] = useState("");
  const publishData = async () => {
    if (ignitionStatus === "") alert("Missing Ignition Over Can Status");
    else if (chargeMosfetStatus === "") alert("Missing Mosfet Status");
    else {
      setIgnitionLoader("loaded");
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem("token")
      );
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          did: id,
          evc: "501",
          payload: {
            ignitionOverCANFlag: parseInt(ignitionStatus),
            dischargeFET: parseInt(chargeMosfetStatus),
            chargeFET: parseInt(chargeMosfetStatus),
          },
        }),
      };
      fetch(API_URL + "/bms/publish_data", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.message === "Msg Published") {
            sleep(8000).then(() => {
              window.location.reload()
            });
          } else {
            alert("Something went wrong.");
          }
        })
        .catch((error) => console.log("error", error));
    }
  };
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const getData = (message) => {
    setIsMqttData(true);
    
    console.log("MQTT message: ", message);
    if (message.evc === "471") {
      setValueDataM(message);
      // console.log("value", message)
    } else if (message.evc === "472") {
      setIntBattV(message.ext[0].ioaValues.intBattV);
      setIoaTime(message.ext[0].tms);
    } else if (message.evc === "477") {
      setLocationDataM(message);
      // console.log("location", message)
    } else if (message.evc === "474") {
      setFaultDataM(message);
      // console.log("fault", message)
    } else if (message.evc === "506") {
      setConfigurationDataM(message);
      // console.log("configuration", message)
    }
  };

  if (window.localStorage.getItem("token") === null)
    window.location.href = "/login";
  else {
    if (packData) {
      if (homeBmsValues) {
        let returnType;
        let headerStyle;
        if (packData.online || isMqttData) {
          returnType = <Badge color="success">Online</Badge>;
          headerStyle = {
            fontWeight: "bolder",
            color: "rgba(50, 200, 90, 1)",
          };
        } else {
          returnType = <Badge color="danger">Offline</Badge>;
          headerStyle = {
            fontWeight: "bolder",
            color: "rgba(200, 40, 40, 1)",
          };
        }
        return (
          <>
            <div className="main-margin">
              <Card
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  padding: "5px",
                  marginRight: "10px",
                }}
              >
                <Row>
                  <Col md="4" xs="6" sm="6" lg="4">
                    <div style={{ fontWeight: "bold" }}>BMS ID</div>
                    <div style={headerStyle}>
                      {id}
                    </div>
                  </Col>
                  <Col md="4" xs="6" sm="6" lg="4">
                    <div style={{ fontWeight: "bold" }}>Last Updated</div>
                    <div style={headerStyle}>
                      {ioaTime ? (
                        <>
                          {ioaTime.slice(11, 19)}
                          &ensp;
                          {ioaTime.slice(8, 10)}/
                          {ioaTime.slice(5, 7)}/
                          {ioaTime.slice(0, 4)}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </Col>
                  <Col md="4" xs="6" sm="6" lg="4">
                    <div style={{ fontWeight: "bold" }}>Status</div>
                    <div style={{ fontWeight: "bolder" }}>{returnType}</div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4" xs="6" sm="6" lg="4">
                    <UncontrolledPopover
                      target="PopoverForAlias"
                      trigger="legacy"
                      placement="bottom"
                    >
                      <PopoverHeader>Edit Alias</PopoverHeader>
                      <PopoverBody>
                        <input
                          onChange={(event) => setAlias(event.target.value)}
                          placeholder="New Alias"
                        ></input>
                        <Button onClick={() => editAlias(packData.bmsId)}>
                          Submit
                        </Button>
                      </PopoverBody>
                    </UncontrolledPopover>
                    <div style={{ fontWeight: "bold" }}>Alias</div>
                    <div style={headerStyle}>
                      {packData.alias}
                      <span
                        id="PopoverForAlias"
                        onClick={() => setIdForAlias(packData.bmsId)}
                        style={{
                          color: "#17a2b8",
                          backgroundColor: "white",
                          borderWidth: 0,
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                      >
                        <i class="fas fa-pen"></i>
                      </span>
                    </div>
                  </Col>
                  <Col md="4" xs="6" sm="6" lg="4">
                    <div style={{ fontWeight: "bold" }}>Ignition Lock</div>
                    <div style={headerStyle}>
                      {homeBmsValues.ignitionOverCANFlag ? "Enabled" : "Disabled"}
                      <span
                        id="popoverFocus"
                        style={{
                          color: "#17a2b8",
                          backgroundColor: "white",
                          borderWidth: 0,
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                      >
                        <i class="fas fa-pen"></i>
                      </span>
                    </div>
                    <UncontrolledPopover
                      placement="bottom"
                      target="popoverFocus"
                      trigger="legacy"
                    >
                      <PopoverBody>
                        <Container>
                          <strong>Ignition Lock</strong>
                          <br />
                          <select
                            onChange={(event) =>
                              setIgnitionStatus(event.target.value)
                            }
                          >
                            <option value="" disabled hidden selected>
                              Select Status
                            </option>
                            <option value="0">Disabled</option>
                            <option value="1">Enabled</option>
                          </select>
                          <br />
                          <strong>Charge & Discharge Mosfets</strong>
                          <br />
                          <select
                            onChange={(event) =>
                              setChargeMosfetStatus(event.target.value)
                            }
                          >
                            <option value="" disabled hidden selected>
                              Select Status
                            </option>
                            <option value="0">Off</option>
                            <option value="1">On</option>
                          </select>
                          <br />
                          <Button color="success" onClick={publishData}>
                            {ignitionLoader === "loaded" ? "Setting..." : "Set"}
                          </Button>
                        </Container>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </Col>
                  <Col md="4" xs="6" sm="6" lg="4">
                    <div style={{ fontWeight: "bold" }}>Internal Battery Voltage</div>
                    <div style={headerStyle}>{intBattV ? `${intBattV / 1000} V` : 'No Data'}</div>
                  </Col>
                </Row>
              </Card>
              <bmsIdContext.Provider value={id}>
                <homeBmsValuesContext.Provider value={homeBmsValues}>
                  <recordsContext.Provider value={records}>
                    <valueDataContext.Provider value={valueData}>
                      <faultDataContext.Provider value={faultData}>
                        <valueDataMContext.Provider value={valueDataM}>
                          <faultDataMContext.Provider value={faultDataM}>
                            <locationDataMContext.Provider value={locationDataM}>
                              <configurationDataMContext.Provider
                                value={configurationDataM}
                              >
                                <packDataContext.Provider value={packData}>
                                  <Tabloid
                                    homeBmsValues={homeBmsValues}
                                    moduleNumber={packData.moduleNumber}
                                  />
                                </packDataContext.Provider>
                              </configurationDataMContext.Provider>
                            </locationDataMContext.Provider>
                          </faultDataMContext.Provider>
                        </valueDataMContext.Provider>
                      </faultDataContext.Provider>
                    </valueDataContext.Provider>
                  </recordsContext.Provider>
                </homeBmsValuesContext.Provider>
              </bmsIdContext.Provider>
            </div>
          </>
        );
      }
      else return (
        <div>
          <h5>No BMS data yet. Please wait for the BMS to send data.</h5>
        </div>
      );
    } else {
      return (
        <div className="content">
          <div className="loadingContainer">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
          </div>
        </div>
      );
    }
  }
}
export default Dashboard;
export {
  valueDataContext,
  faultDataContext,
  valueDataMContext,
  bmsIdContext,
  faultDataMContext,
  locationDataMContext,
  configurationDataMContext,
  recordsContext,
  homeBmsValuesContext
};
