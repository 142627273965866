import React, { useState } from 'react'

function SendCommands(props) {
    const [BMSUDID, setBMSUDID] = useState('')
    const getBMSUDID = (second) => {
        setBMSUDID(second.target.value)
    }
    const SendBMSUDID = (second) => {
        if (BMSUDID) {
            let newArray = [0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            let array = BMSUDID.split('')
            for (let index = 0; index < array.length; index++) {
                newArray[index] = array[index].charCodeAt(0).toString(16)
            }
            let dataArray = [0x00, '0x' + newArray[0], '0x' + newArray[1], '0x' + newArray[2], '0x' + newArray[3], '0x' + newArray[4], '0x' + newArray[5], '0x' + newArray[6]];
            let canId = props.integerTo4ByteArray(512496499);
            props.CommandCenter(canId, 1, dataArray);
            if (array.length > 7) {
                let dataArray = [0x01, '0x' + newArray[7], '0x' + newArray[8], '0x' + newArray[9], '0x' + newArray[10], '0x' + newArray[11], '0x' + newArray[12], '0x' + newArray[13]];
                let canId = props.integerTo4ByteArray(512496499);
                props.CommandCenter(canId, 1, dataArray);
            }
        }

    }
    return (
        <div><input type='text' placeholder='ID here' onChange={getBMSUDID} maxLength={14}></input>
            <button onClick={SendBMSUDID}>Send</button>
        </div>
    )
}

export default SendCommands