import React, { useEffect, useState, useContext } from 'react'
import { bmsIdContext } from "../../views/Dashboard";
import BmsBootLogs from './BmsBootLogs';
import IoTLogs from './IoTLogs';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { API_URL } from 'variables/general';
import BMSLogs from './BMSLogs';
import LocationDataLog from './LocationDataLog';
import IoaLogs from './IoaLogs';
function LogsTab() {
  useEffect(() => {
    fetchBmsBoot()
    fetchIotBoot()
  }, [])
  const id = useContext(bmsIdContext);
  const [iconPills, setIconPills] = useState("1");
  const [noOfSamples, setNoOfSamples] = useState(20)
  const [tms, setTms] = useState('')
  const [bmsBootData, setBmsBootData] = useState()
  const [iotBootData, setIotBootData] = useState()
  const [showRecent, setShowRecent] = useState('Recent ')
  const [loader, setLoader] = useState(true)

  const tableStyle = {
    tableHeaderStyle: {
      position: "sticky",
      top: 0,
      backgroundColor: "#30428c",
      color: "white",
      zIndex: 1,
      padding: "5px",
    },
    tableRowStyle: {
      fontWeight: "700",
      padding: "5px",
      borderBottom: "2px solid #c1d6c6",
    }
  }


  const fetchIotBoot = (val, lt) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        id: id,
        limit: parseInt(noOfSamples),
        boot: 'iot'
      }),
      redirect: "follow",
    };
    fetch(API_URL + "/bms/v2/boot_history", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // let bms = (result.data.data.sort((a, b) => new Date(a.tms) - new Date(b.tms))).reverse();
        const iot = (result.data.data.sort((a, b) => new Date(a.tms) - new Date(b.tms))).reverse();
        // setBmsBootData(bms)
        setIotBootData(iot)
        // setLoader(false)
      }).catch((error) => console.log("error", error));
  };
  const fetchBmsBoot = (val, lt) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    if (lt === "LT") {
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          batteryPackId: id,
          limit: parseInt(noOfSamples),
          tms_lt: val,
          boot: 'bms'
        }),
      };

    } else
      if (lt === 'TMS') {
        requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            id: id,
            limit: parseInt(noOfSamples),
            tms: val,
            boot: 'bms'
          }),
        };
      } else
        requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            id: id,
            limit: parseInt(noOfSamples),
            boot: 'bms'
          }),
          redirect: "follow",
        };
    fetch(API_URL + "/bms/v2/boot_history", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let bms = (result.data.data.sort((a, b) => new Date(a.tms) - new Date(b.tms))).reverse();
        // let iot = (result.data.iot.sort((a, b) => new Date(a.tms) - new Date(b.tms))).reverse();
        setBmsBootData(bms)
        // setIotBootData(iot)
        setLoader(false)
      }).catch((error) => console.log("error", error));
  };
  const getTms = (val) => {
    setTms(val.target.value)
  }
  const previousClicked = (val) => {
    setShowRecent('')
    fetchBmsBoot(val, "LT");
  };
  const nextClicked = (val) => {
    setShowRecent('')
    fetchBmsBoot(val, "TMS");
  };
  const getCenter = () => {
    fetchBmsBoot();
    setShowRecent('Recent ')
  };
  const changeNoOfSample = (val) => {
    setNoOfSamples(val)
  };
  const getDataForTms = () => {
    fetchBmsBoot(tms, "TMS");
  }
  const buttonBasics = {
    borderWidth: 0,
    borderRadius: "20px",
    backgroundColor: "#B6BDBB",
    color: "white",
    padding: "5px 15px 5px 15px",
    margin: "5px 50px",
    fontWeight: "bold",
  };
  if (!loader)
    return (
      <div>
        <Nav className="justify-content-center" role="tablist" tabs>
          <NavItem>
            <NavLink
              className={iconPills === "1" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills('1')
              }}
            >
              <strong>BMS Data Logs</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "2" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills('2')
              }}
            >
              <strong>Location Data Logs</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "3" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills('3')
              }}
            >
              <strong>BMS Boot Logs</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={iconPills === "4" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills('4')
              }}
            >
              <strong>IoT Boot Logs</strong>
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={iconPills === "5" ? "active" : ""}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setIconPills('5')
              }}
            >
              <strong>IOA Logs</strong>
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
          <TabPane tabId="iconPills1">
            <BMSLogs tableStyle={tableStyle}/>
          </TabPane>
          <TabPane tabId="iconPills2">
            <LocationDataLog  tableStyle={tableStyle}/>
          </TabPane>
          <TabPane tabId="iconPills3">
            <BmsBootLogs bmsBootData={bmsBootData} tableStyle={tableStyle}/>
          </TabPane>
          <TabPane tabId="iconPills4">
            <IoTLogs iotBootData={iotBootData} tableStyle={tableStyle}/>
          </TabPane>
          {/* <TabPane tabId="iconPills5">
            <IoaLogs tableStyle={tableStyle}/>
          </TabPane> */}
        </TabContent>
      </div>
    )
  else return <div>Loading...</div>
}

export default LogsTab