import { CardBody, CardHeader, CardTitle } from "reactstrap";
import { Card, Col, Row } from "react-bootstrap";
import BatteryCells from "./BatteryCells";
import { Doughnut } from "react-chartjs-2";
import Faults from "components/Faults/Faults";
import Power from "assets/img/power.png";
import Temperature from "components/Temperature/Temperature";
import Voltage from "assets/img/voltage.png";
import React,{ useContext,useState } from "react";
import { valueDataContext, valueDataMContext } from "./Dashboard";

function Home(props) {
  const [selectedModule, setSelectedModule] = useState('All')
  const moduleNumber = props.moduleNumber
  const valueDataItem = props.homeBmsValues
  const valueDataM = useContext(valueDataMContext)
  if (valueDataItem) {
    let soC;
    let soH;
    let soP;
    soC = valueDataM ? valueDataM.ext[0].bmsValues.soC : valueDataItem.soC;
    soH = valueDataM ? valueDataM.ext[0].bmsValues.soH : valueDataItem.soH;
    soP = valueDataM ? valueDataM.ext[0].bmsValues.soP : valueDataItem.soP;
    let soCBG;
    let soHBG;
    let soPBG;
    if (soC >= 50) soCBG = "#32c85a";
    else if (soC < 50 && soC >= 20) soCBG = "#c7af1e";
    else if (soC < 20) soCBG = "#c84432";
    else soCBG = "#32c8be";
    if (soH >= 50) soHBG = "#32c85a";
    else if (soH < 50 && soH >= 20) soHBG = "#c7af1e";
    else if (soH < 20) soHBG = "#c84432";
    else soHBG = "#32c8be";
    if (soP >= 50) soPBG = "#32c85a";
    else if (soP < 50 && soP >= 20) soPBG = "#c7af1e";
    else if (soP < 20) soPBG = "#c84432";
    else soPBG = "#32c8be";
    const soCChart = {
      data: (canvas) => {
        return {
          labels: [1, 2, 3],
          datasets: [
            {
              label: "Emails",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#e3e3e3", soCBG],
              borderWidth: 0,

              data: [100 - soC, soC],
            },
          ],
        };
      },
      options: {
        legend: {
          display: false,
        },

        pieceLabel: {
          render: "percentage",
          fontColor: ["white"],
          precision: 2,
        },

        tooltips: {
          enabled: false,
        },

        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)",
              },
            },
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    };
    const soHChart = {
      data: (canvas) => {
        return {
          labels: [1, 2, 3],
          datasets: [
            {
              label: "Emails",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#e3e3e3", soHBG],
              borderWidth: 0,

              data: [100 - soH, soH],
            },
          ],
        };
      },
      options: {
        legend: {
          display: false,
        },

        pieceLabel: {
          render: "percentage",
          fontColor: ["white"],
          precision: 2,
        },

        tooltips: {
          enabled: false,
        },

        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)",
              },
            },
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    };
    const soPChart = {
      data: (canvas) => {
        return {
          labels: [1, 2, 3],
          datasets: [
            {
              label: "Emails",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: ["#e3e3e3", soPBG],
              borderWidth: 0,

              data: [100 - soP, soP],
            },
          ],
        };
      },
      options: {
        legend: {
          display: false,
        },

        pieceLabel: {
          render: "percentage",
          fontColor: ["white"],
          precision: 2,
        },

        tooltips: {
          enabled: false,
        },

        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: "rgba(255,255,255,0.05)",
              },
            },
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(255,255,255,0.1)",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    };
    return (
      <div>
        <Row>
          <Col xs="12" sm="12" md="3">
            <Card className="hero-cards">
              <Row>
                <Col md="4">
                  <img src={Voltage}></img>
                </Col>
                <Col>
                  <div className="card-head">Pack Voltage </div>
                  <div className="card-foot">
                    {Math.round(
                      (valueDataM ? valueDataM.ext[0].bmsValues.packVoltage / 1000 : valueDataItem.packVoltage / 1000) *
                      100
                    ) / 100}
                    V
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="3">
            <Card className="hero-cards">
              <Row>
                <Col md="4">
                  <img src={Power}></img>
                </Col>
                <Col>
                  <div className="card-head"> Instantaneous Power </div>
                  <div className="card-foot">
                    {Math.round(
                      (valueDataM ? valueDataM.ext[0].bmsValues.packPower / 1000 : valueDataItem.packPower / 1000) *
                      100
                    ) / 100}
                    kW
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs="12" sm="12" md="6">
            <Card className="hero-cards">
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <div className="card-head">
                        Instantaneous Current:
                        <div className="current-card">
                          {
                            valueDataM ?
                              <div className="current-card">
                                {Math.round(
                                  (valueDataM.ext[0].bmsValues.packCurrent / 1000) *
                                  100
                                ) / 100}
                                A
                              </div>
                              :
                              valueDataItem
                                .packCurrent ? (
                                <div className="current-card">
                                  {Math.round(
                                    (valueDataItem.packCurrent / 1000) *
                                    100
                                  ) / 100}
                                  A
                                </div>
                              ) : (
                                valueDataItem.packCurrent === 0 ?
                                  <div className="current-card">0 A</div> :
                                  <div className="current-card">-</div>
                              )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="card-head">
                        Average Pack Current:
                        <div className="current-card">
                          {
                            valueDataM ?
                              <div className="current-card">
                                {Math.round(
                                  (valueDataM.ext[0].bmsValues.avgPackCurrent / 1000) *
                                  100
                                ) / 100}
                                A
                              </div>
                              :
                              valueDataItem
                                .avgPackCurrent ? (
                                <div className="current-card">
                                  {Math.round(
                                    (valueDataItem.avgPackCurrent / 1000) *
                                    100
                                  ) / 100}
                                  A
                                </div>
                              ) : (
                                valueDataItem.avgPackCurrent === 0 ? <div className="current-card">0 A</div> : <div className="current-card">-</div>
                              )}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="card-head">
                        Maximum Pack Current:
                        <div className="current-card">
                          {
                            valueDataM ?
                              <div className="current-card">
                                {Math.round(
                                  (valueDataM.ext[0].bmsValues.maxPackCurrent / 1000) *
                                  100
                                ) / 100}
                                A
                              </div>
                              :
                              valueDataItem
                                .maxPackCurrent ? (
                                <div className="current-card">
                                  {Math.round(
                                    (valueDataItem.maxPackCurrent / 1000) *
                                    100
                                  ) / 100}
                                  A
                                </div>
                              ) : (
                                valueDataItem.maxPackCurrent === 0 ? <div className="current-card">0 A</div> : <div className="current-card">-</div>
                              )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="6" md="3">
            <Card className="hero-cards">
              <Row>
                <Col>
                  <div className="card-head">Charging</div>
                  <div style={{ padding: "5px" }} className="card-foot">
                    {valueDataM ? valueDataM.ext[0].cellTempData.chargeFET === 1 ? "On"
                      : "Off" : valueDataItem.chargeFet === 1
                      ? "On"
                      : "Off"}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs="6" sm="6" md="3">
            <Card className="hero-cards">
              <Row>
                <Col>
                  <div className="card-head">Discharging</div>
                  <div style={{ padding: "5px" }} className="card-foot">
                    {valueDataM ? valueDataM.ext[0].cellTempData.dischargeFET === 1 ? "On"
                      : "Off" : valueDataItem.dischargeFet === 1
                      ? "On"
                      : "Off"}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs="6" sm="6" md="3">
            <Card className="hero-cards">
              <Row>
                <Col>
                  <div className="card-head">Pack Power Mode</div>
                  <div
                    style={{ padding: "4px", textTransform: "uppercase" }}
                    className="card-foot"
                  >
                    {valueDataM ? valueDataM.ext[0].cellTempData.bmsPowerMode : valueDataItem.bmsPowerMode}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs="6" sm="6" md="3">
            <Card className="hero-cards">
              <Row>
                <Col>
                  <div className="card-head">Pack Current State</div>
                  <div
                    style={{ padding: "4px", textTransform: "uppercase" }}
                    className="card-foot"
                  >
                    {valueDataM ? valueDataM.ext[0].cellTempData.packCurrentState : valueDataItem.packCurrentState}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="7">
              <BatteryCells valueDataItem={valueDataItem} valueDataM={valueDataM} moduleNumber={moduleNumber} setSelectedModule={setSelectedModule} selectedModule={selectedModule} />
          </Col>
          <Col xs="12" sm="12" md="5">
            <Row>
              <Col>
                <Faults valueDataItem={valueDataItem}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Temperature valueDataItem={valueDataItem}  moduleNumber={moduleNumber} setSelectedModule={setSelectedModule} selectedModule={selectedModule}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h6">
                  State of Charge
                  <div className="card-foot">
                    {valueDataM ? valueDataM.ext[0].bmsValues.soC : valueDataItem.soC} %
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Doughnut data={soCChart.data} options={soCChart.options} />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h6">
                  State of Health
                  <div className="card-foot">
                    {valueDataM ? valueDataM.ext[0].bmsValues.soH : valueDataItem.soH} %
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Doughnut data={soHChart.data} options={soHChart.options} />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h6">
                  State of Power
                  <div className="card-foot">
                    {valueDataM ? valueDataM.ext[0].bmsValues.soP : valueDataItem.soP} %
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Doughnut data={soPChart.data} options={soPChart.options} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="3" sm="6" xs="6">
            <Card>
              <CardHeader>
                <CardTitle>
                  <h6>Cycle count</h6>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="card-foot">
                  {valueDataM ? valueDataM.ext[0].bmsValues.cycleCount : valueDataItem.cycleCount}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3" sm="6" xs="6">
            <Card>
              <CardHeader>
                <CardTitle>
                  <h6>Energy In</h6>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="card-foot">
                  {valueDataM ? valueDataM.ext[0].bmsValues.energyIn : valueDataItem.energyIn} W
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3" sm="6" xs="6">
            <Card>
              <CardHeader>
                <CardTitle>
                  <h6>Energy Out</h6>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="card-foot">
                  {valueDataM ? valueDataM.ext[0].bmsValues.energyOut : valueDataItem.energyOut} W
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3" sm="6" xs="6">
            <Card>
              <CardHeader>
                <CardTitle>
                  <h6>Remaining Capacity</h6>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="card-foot">
                  {valueDataM ? valueDataM.ext[0].bmsValues.remainingCapacity : valueDataItem.remainingCapacity}
                  mAh
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
  else {
    return (
      <div className="content">
        <div className="loadingContainer">
          <div className="ball1"></div>
          <div className="ball2"></div>
          <div className="ball3"></div>
          <div className="ball4"></div>
        </div>
      </div>
    );
  }
}


export default Home;
