import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from "reactstrap";
import Home from "./Home";
import FirmwareUpdate from "./FirmwareUpdate/FirmwareUpdate";
import RawData from "./RawData";
import Analytics from "./Analytics/Analytics";
import DataLogging from "./DataLogging/DataLogging";
import EditConfiguration from "./EditConfiguration";
import CurrentSetting from "./CurrentSetting";
import Paralleling from "./Paralleling";
import FirmwareTab from "./FirmwareUpdate/Tab";
import SendCommands from "./SendCommands/SendCommands";
import ChargerCalibration from "./Charger/ChargerCalibration";
// import Charger from "./Charger/Charger";
function Tab(props) {
  const [iconPills, setIconPills] = React.useState("1");
  const [showConfigData, setShowConfigData] = React.useState(false);
  return (
    <div>
      <Nav className="justify-content-center" role="tablist" tabs>
        <NavItem>
          <NavLink
            className={iconPills === "1" ? "active" : ""}
            style={{ cursor: "pointer", float: "left" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("1");
            }}
          >
            <strong>Home</strong>
          </NavLink>
          <NavLink
            className={iconPills === "2" ? "active" : ""}
            style={{ cursor: "pointer", float: "left" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("2");
              setShowConfigData(true);
            }}
            disabled={props.fetchConfigDisabled}
          >
            <strong>Configuration</strong>
          </NavLink>
          <NavLink
            className={iconPills === "3" ? "active" : ""}
            style={{ cursor: "pointer", float: "left" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("3");
            }}
          >
            <strong>Firmware Update</strong>
          </NavLink>
          {window.localStorage.getItem("role") === "admin"?
          <>
          <NavLink
            className={iconPills === "4" ? "active" : ""}
            style={{ cursor: "pointer", float: "left" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("4");
            }}
            >
            <strong>Send Command</strong>
          </NavLink>
          <NavLink
            className={iconPills === "5" ? "active" : ""}
            style={{ cursor: "pointer", float: "left" }}
            onClick={(e) => {
              e.preventDefault();
              setIconPills("5");
            }}
            >
            <strong>Charger Calibration</strong>
          </NavLink>
            </>
          
          :''}
        </NavItem>
      </Nav>
      <TabContent className="text-center" activeTab={"iconPills" + iconPills}>
        <TabPane tabId="iconPills1">
          <Home
          showUiFor24SPlus={props.showUiFor24SPlus}
          packetIndex={props.packetIndex}
          CellTempPacketIndex={props.CellTempPacketIndex}
          ExtrTempPacketIndex={props.ExtrTempPacketIndex}
          cellVoltageFor24SPlus={props.cellVoltageFor24SPlus}
          BMS_NoOfCellAvailable={props.BMS_NoOfCellAvailable}
          BMS_NoOfTempAvailable={props.BMS_NoOfTempAvailable}
          cellTempFor24SPlus={props.cellTempFor24SPlus}
          BMS_NoOfExterTempAvailable={props.BMS_NoOfExterTempAvailable}
          extrTempFor24SPlus={props.extrTempFor24SPlus}
            batteryVoltage={props.batteryVoltage}
            current={props.current}
            AvrgCellTemp={props.AvrgCellTemp}
            AvrgCellVolt={props.AvrgCellVolt}
            SOC={props.SOC}
            DisFET={props.DisFET}
            ChgFET={props.ChgFET}
            BattLow={props.BattLow}
            BattHigh={props.BattHigh}
            CellHigh={props.CellHigh}
            IGN_S={props.IGN_S}
            CellLow={props.CellLow}
            FullChargeBit={props.FullChargeBit}
            AvailableCapacity={props.AvailableCapacity}
            CyclesCount={props.CyclesCount}
            SOH={props.SOH}
            SOP={props.SOP}
            LowSOC_Indication={props.LowSOC_Indication}
            CAN_LoginStatusFlag={props.CAN_LoginStatusFlag}
            IgnitionOverCANFlag={props.IgnitionOverCANFlag}
            BalancingStatus={props.BalancingStatus}
            PDU_Temp={props.PDU_Temp}
            MaxCellVolt={props.MaxCellVolt}
            MinCellVolt={props.MinCellVolt}
            MaxCellTemp={props.MaxCellTemp}
            MinCellTemp={props.MinCellTemp}
            AverageCellVolt={props.AverageCellVolt}
            NumberOfCell={props.NumberOfCell}
            Cell1={props.Cell1}
            Cell2={props.Cell2}
            Cell3={props.Cell3}
            cell4={props.cell4}
            Cell5={props.Cell5}
            Cell6={props.Cell6}
            Cell7={props.Cell7}
            cell8={props.cell8}
            Cell9={props.Cell9}
            Cell10={props.Cell10}
            Cell11={props.Cell11}
            cell12={props.cell12}
            Cell13={props.Cell13}
            Cell14={props.Cell14}
            Cell15={props.Cell15}
            cell16={props.cell16}
            Cell17={props.Cell17}
            Cell18={props.Cell18}
            Cell19={props.Cell19}
            cell20={props.cell20}
            Cell21={props.Cell21}
            Cell22={props.Cell22}
            Cell23={props.Cell23}
            Cell24={props.Cell24}
            CellMaxMinDiff={props.CellMaxMinDiff}
            NumOfTemp={props.NumOfTemp}
            Temp1={props.Temp1}
            Temp2={props.Temp2}
            Temp3={props.Temp3}
            Temp4={props.Temp4}
            Temp5={props.Temp5}
            Temp6={props.Temp6}
            Temp7={props.Temp7}
            Temp8={props.Temp8}
            IC1Temp={props.IC1Temp}
            IC2Temp={props.IC2Temp}
            PDUTemp={props.PDUTemp}
            PCBTemp2={props.PCBTemp2}
            HeatSink1Temp={props.HeatSink1Temp}
            HeatSink2Temp={props.HeatSink2Temp}
            bmsPowerMode={props.bmsPowerMode}
            packCurrentState={props.packCurrentState}
            Alarm={props.Alarm}
            Fault={props.Fault}
            averageTimeToEmpty={props.averageTimeToEmpty}
            averageTimeToFull={props.averageTimeToFull}
            DisChargeMOSFETRx={props.DisChargeMOSFETRx}
            ChargeMOSFETRx={props.ChargeMOSFETRx}
            IgnitionOverCANFlagrx={props.IgnitionOverCANFlagrx}
            WarningFlags={props.WarningFlags}
            EnergyOut={props.EnergyOut}
            EnergyIn={props.EnergyIn}
            BMSConfigVersion_CRC16bit={props.BMSConfigVersion_CRC16bit}
            BMS_CommandChar1={props.BMS_CommandChar1}
            BMS_CommandChar2={props.BMS_CommandChar2}
            BMS_CommandChar3={props.BMS_CommandChar3}
            BMS_Command1={props.BMS_Command1}
            BMS_Command2={props.BMS_Command2}
            BMS_Command3={props.BMS_Command3}
            BMS_Command4={props.BMS_Command4}
            BMS_Command5={props.BMS_Command5}
            DEV_ON_OFF={props.DEV_ON_OFF}
            ISL_GLOBAL_FAULT={props.ISL_GLOBAL_FAULT}
            ISL_GLOBAL_EN={props.ISL_GLOBAL_EN}
            BMS_INGITION={props.BMS_INGITION}
            D_MOSFETs={props.D_MOSFETs}
            C_MOSFETs={props.C_MOSFETs}
            P_MOSFETs={props.P_MOSFETs}
            SCD_ALERT={props.SCD_ALERT}
            V5_ON_OFF={props.V5_ON_OFF}
            STATUS_PIN={props.STATUS_PIN}
            POWER_GOOD_PIN={props.POWER_GOOD_PIN}
          />
        </TabPane>
        <TabPane tabId="iconPills2">
          <EditConfiguration
            integerTo4ByteArray={props.integerTo4ByteArray}
            CommandCenter={props.CommandCenter}
            CANLoginCommandSend={props.CANLoginCommandSend}
            configurationArray={props.configurationArray}
            sendConfig={props.sendConfig}
            doShowData={props.doShowData}
            setDoShowData={props.setDoShowData}
            fetchConfigDisabled={props.fetchConfigDisabled}
            sendConfigDisabled={props.sendConfigDisabled}
            showConfigData={showConfigData}
            fetchConfiguration={props.fetchConfiguration}
          />
        </TabPane>
        <TabPane tabId="iconPills3">
          <FirmwareTab   UpdateCode={props.UpdateCode}
            BinFileInput={props.BinFileInput}
            incrementXModem={props.incrementXModem}
            xModem128ByteChunk={props.xModem128ByteChunk}
            fotaSuccessful={props.fotaSuccessful}
            inFotaMode={props.inFotaMode}
            fotaProcess={props.fotaProcess}
            startUpdatingDisabled={props.startUpdatingDisabled}
            fileUploaded={props.fileUploaded}
            apiFOTAFile={props.apiFOTAFile}
            showLoaderInApiDownload={props.showLoaderInApiDownload}
            apiFileUploadSuccess={props.apiFileUploadSuccess}
            apiFotaFileSize={props.apiFotaFileSize}
            setApiFotaFileInputHandler={props.setApiFotaFileInputHandler}
            ChargerCANBootLoaderCommandSend={props.ChargerCANBootLoaderCommandSend}
            ChargerCANLoginCommandSend={props.ChargerCANLoginCommandSend}/>
        </TabPane>
        {window.localStorage.getItem("role") === "admin"?
        <>
        <TabPane tabId="iconPills4">
          <SendCommands CommandCenter={props.CommandCenter} integerTo4ByteArray={props.integerTo4ByteArray}/>
        </TabPane>
        <TabPane tabId="iconPills5">
          <ChargerCalibration CommandCenter={props.CommandCenter} integerTo4ByteArray={props.integerTo4ByteArray} integerTo2ByteArray={props.integerTo2ByteArray} chargerDataRecieved={props.chargerDataRecieved} byteArrayToInt2={props.byteArrayToInt2}/>
        </TabPane>
        </>
        :""}
      </TabContent>
    </div>
  );
}

export default Tab;
