import React from 'react'
function BmsBootLogs(props) {
    const BmsBootData = props.bmsBootData
    const tableHeaderStyle = {
        position: "sticky",
        top: 0,
        backgroundColor: "#30428c",
        color: "white",
        zIndex: 1,
        padding: "5px",
    };
    const tableRowStyle = {
        fontWeight: "700",
        padding: "5px",
        borderBottom: "2px solid #c1d6c6",
    };
    if (BmsBootData) {
        return (
            <div>
                <table
                    style={{
                        overflow: "scroll",
                        display: "block",
                        height: "95vh",
                    }}
                >
                    <thead style={tableHeaderStyle}>
                        <tr>
                            <th style={tableHeaderStyle}>S. No.</th>
                            <th style={tableHeaderStyle}>Time</th>
                            <th style={tableHeaderStyle}>Battery Number</th>
                            <th style={tableHeaderStyle}>Firmware Version</th>
                            <th style={tableHeaderStyle}>Hardware Version</th>
                            <th style={tableHeaderStyle}>BMS Config version</th>
                        </tr>
                    </thead>
                    <tbody>
                        {BmsBootData.map((val, i) =>

                            <tr>
                                <td className="text-center" style={tableRowStyle}>
                                    {i + 1}.
                                </td>
                                <td className="text-center" style={tableRowStyle}>
                                    {val.tms.slice(11, 19)}&ensp;
                                    {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                                    {val.tms.slice(0, 4)}
                                </td>
                                <td className="text-center" style={tableRowStyle}>{val.batteryNumber}
                                </td>
                                <td className="text-center" style={tableRowStyle}>{val.firmwareVersion}
                                </td>
                                <td className="text-center" style={tableRowStyle}>{val.hardwareVersion}
                                </td>
                                <td className="text-center" style={tableRowStyle}>{val.bmsconfigVersion}
                                </td>
                            </tr>

                        )}
                    </tbody>
                </table>
            </div>
        )
    }
    else return <div>Loading...</div>
}

export default BmsBootLogs