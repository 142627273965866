import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { API_URL } from 'variables/general'

function LocationDataLogsCsv(props) {
    const batteryPackId = props.batteryPackId
    const csvLink = useRef();
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [csvData, setCsvData] = useState()
    const [fileName, setFileName] = useState()
    const [loader, setLoader] = useState(false)
    const getStartDate = (date) => {
        setStartDate(date.target.value)
    }
    const getEndDate = (date) => {
        setEndDate(date.target.value)
    }
    const getData = () => {
        if (startDate && endDate) {
            const oneDayInMs = 24 * 60 * 60 * 1000; // One day in milliseconds
            const diffInMs = Math.abs(new Date(endDate) - new Date(startDate)); // Difference in milliseconds
            const Difference = Math.ceil(diffInMs / oneDayInMs); // Convert milliseconds to days
            if (new Date(startDate) <= new Date(endDate) && (Difference < 16)) {
                setLoader(true)
                getApiData()
            }
            else {
                alert(`End Date should be more than Start Date and Max limit is 15 days.`)
            }
        }
    }
    const sleep = (time) => {
        return new Promise(resolve => setTimeout(resolve, time))
    }
    function getApiData(date) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem("token")
        );
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({
                did: batteryPackId,
                tms_gt: startDate + 'T00:00:00Z',
                tms_lt: endDate + 'T23:59:59Z',
            }),
        };
        fetch(API_URL + "/bms/bms_location", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.data.bmsLocation.length > 0) {
                    setFileName('location_' + batteryPackId + '_' + startDate + '_' + endDate)
                    setCsvData(result.data.bmsLocation)
                    triggerDownload()
                    setLoader(false)
                }
                else alert(`Data not found`)
            }).catch((error) => console.log("error", error));
    }
    const triggerDownload = async () => {
        await csvLink.current.link.click(); // Programmatically trigger the download
    };
    if (batteryPackId)
        return (
            <div>
                <h6>Download Data Logs in CSV</h6>
                <strong>Start Date:</strong>&ensp;<input type='date' onChange={getStartDate}></input>&ensp;
                <strong>End Date:</strong>&ensp;<input type='date' onChange={getEndDate}></input>&ensp;
                <button onClick={getData}>{loader ? 'Loading...' : 'Submit'}</button>
                {csvData ? <CSVLink
                    data={csvData}
                    filename={fileName}
                    ref={csvLink}
                    style={{ display: 'none' }} // Hide the link
                /> : ''}
            </div>
        )
    else return <div>Loading....</div>
}
export default LocationDataLogsCsv