import { Bar, Line } from "react-chartjs-2";
import React, { useEffect } from "react";
import SingleModule from "assets/img/singleModule.png"
import Rack from "assets/img/rack.png"
import Arrow from "assets/img/longarrow.png";
import { Badge, Card, Col, Row, Tooltip } from "reactstrap";
import { useState } from "react";
import { valueDataMContext, valueDataContext } from "./Dashboard";
import { useContext } from "react";

function BatteryCells(props) {
  const [selectedModule, setSelectedModule] = [props.selectedModule, props.setSelectedModule]
  const moduleNumber = props.moduleNumber
  const valueDataM = useContext(valueDataMContext)
  const dataFrom = props.dataFrom
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  let cellVoltageRaw = []
  let cellVoltageData = []
  let dividedArray
  if (dataFrom === "BLEView") {
    cellVoltageData = props.valueDataItem
  }
  else {
    if (valueDataM) {
      cellVoltageRaw = valueDataM.ext[0].cellTempData.cellVoltage
    }
    else
      cellVoltageRaw = JSON.parse(props.valueDataItem.cellVoltage)
  }
  function divideArray(arr, totalChunks) {
    let result = [];
    let chunkSize = Math.ceil(arr.length / totalChunks);
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }
  const showElementArray = Array(moduleNumber).fill(0)
  if (moduleNumber > 1) {
    dividedArray = divideArray(cellVoltageRaw, moduleNumber)
    if (selectedModule !== 'All')
      cellVoltageData = dividedArray[selectedModule]
    else
      cellVoltageData = cellVoltageRaw
  }
  else {
    cellVoltageData = cellVoltageRaw
  }
  let cellSum = 0
  let cellAverage
  let cellValue = []
  let cellLabel = []
  let cellColor = []
  for (let i = 0; i < cellVoltageData.length; i++) {
    cellSum = cellSum + Number(cellVoltageData[i]);
  }
  cellAverage = cellSum / cellVoltageData.length;
  let maxVoltage = cellVoltageData[0];
  let minVoltage = cellVoltageData[0];
  for (let i = 0; i < cellVoltageData.length; i++) {
    if (maxVoltage <= cellVoltageData[i]) maxVoltage = cellVoltageData[i];
  }
  for (let i = 0; i < cellVoltageData.length; i++) {
    if (minVoltage >= cellVoltageData[i]) minVoltage = cellVoltageData[i];
  }
  for (let i = 0; i < cellVoltageData.length; i++) {
    cellValue[i] = cellVoltageData[i];
  }
  for (let i = 0; i < cellVoltageData.length; i++) {
    cellLabel[i] = selectedModule === 'All' ? i + 1 : selectedModule * cellVoltageData.length + i + 1;
  }
  for (let i = 0; i < cellVoltageData.length; i++) {
    if (cellVoltageData[i] === maxVoltage) cellColor[i] = "#b8b209";
    else if (cellVoltageData[i] === minVoltage) cellColor[i] = "#078da8";
    else cellColor[i] = "rgba(10, 255, 10, 1)";
  }
  const batteryCellsChart = {
    data: () => {
      return {
        labels: cellLabel,
        datasets: [
          {
            label: "Cell Voltage (mV)",
            data: cellValue,
            backgroundColor: cellColor,
            borderColor: [],
            borderWidth: 0,
          },
        ],
      };
    },

    options: {
      legend: {
        display: false,
        position: "top",
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  };
  return (
    <div>

      <Card>
        <div style={{ margin: "15px" }}>
          {moduleNumber > 1 ?
            <div style={{ border: '2px solid #078da8', borderRadius: '5px', padding: '1rem' }}>

              <Row style={{ display: 'flex' }}>
                <Col xs="12" sm="12" md="3" lg="3" xl="3" style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ borderRight: window.innerWidth < 768 ? '' : '2px solid #078da8', flex: '1' }}>
                    {window.innerWidth < 768 ? '' : <br />}
                    <img src={Rack} alt="..." style={{ maxWidth: '30%', alignItems: 'center' }} />
                    <br />
                    <span style={{ fontWeight: 'bolder' }}>Battery Modules</span> <i
                      class="fa fa-question-circle"
                      aria-hidden="true"
                      id='batteryModules'
                    >
                    </i>
                    <Tooltip
                      // {...args}
                      isOpen={tooltipOpen}
                      target="batteryModules"
                      toggle={toggle}
                      placement='right'
                    >
                      Tap on modules to see details
                    </Tooltip>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="9" lg="9" xl="9" style={{ display: 'flex', flexDirection: 'column' }}>
                  <Row>
                    <Col xs="6" sm="6" md="3" lg="3" xl="4">
                      <div id='All' onClick={() => setSelectedModule('All')} style={{ flex: '1', textAlign: 'center', margin: '5px 0', padding: '2px', border: '2px solid #078da8', borderRadius: '5px', backgroundColor: 'All' == selectedModule ? '#078da8' : 'white', cursor: 'pointer', color: 'All' == selectedModule ? 'white' : '#078da8' }}>
                        <img src={SingleModule} alt="..." style={{ maxWidth: '25px' }}></img>
                        <span style={{ fontWeight: 'bolder' }}>
                          All
                        </span>
                      </div>
                    </Col>
                    {
                      showElementArray.map((val, index) =>
                        <Col xs="6" sm="6" md="3" lg="3" xl="4">
                          <div id={index} onClick={() => setSelectedModule(index)} style={{ flex: '1', textAlign: 'center', margin: '5px 0', padding: '2px', border: '2px solid #078da8', borderRadius: '5px', backgroundColor: index == selectedModule ? '#078da8' : 'white', cursor: 'pointer', color: index == selectedModule ? 'white' : '#078da8' }}>
                            <img src={SingleModule} alt="..." style={{ maxWidth: '25px' }}></img>
                            <span style={{ fontWeight: 'bolder' }}>
                              Module {index + 1}
                            </span>
                          </div>
                        </Col>
                      )
                    }
                  </Row>
                </Col>
              </Row>
            </div> : ''}
          <br />
          <div className="card-head">Cell Voltages</div>
          <Row>
            <Col>
              <div>
                <Badge
                  style={{
                    backgroundColor: "#078da8",
                    color: "white",
                    margin: "10px",
                  }}
                >
                  Min: {minVoltage}mV
                </Badge>
                <Badge
                  style={{
                    backgroundColor: "#b8b209",
                    color: "white",

                    margin: "10px",
                  }}
                >
                  Max: {maxVoltage}mV
                </Badge>
                <Badge
                  style={{
                    backgroundColor: "rgba(100, 100, 100, 1)",
                    color: "white",

                    margin: "10px",
                  }}
                >
                  Diff: {maxVoltage - minVoltage}mV
                </Badge>
                <Badge
                  style={{
                    backgroundColor: "rgba(125, 42, 89, 0.75)",
                    color: "white",

                    margin: "10px",
                  }}
                >
                  Avg: {Math.round(cellAverage)}mV
                </Badge>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Bar
                data={batteryCellsChart.data}
                options={batteryCellsChart.options}
              />
              <Row>
                <Col md="3"></Col>
                <Col md="3" className="card-head">
                  Cell Number
                </Col>
                <Col md="3">
                  {" "}
                  <img src={Arrow} />
                </Col>
                <Col md="3"></Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  )
  // else {
  //   return (
  //     <div>
  //       <Spinner>Loading...</Spinner>
  //     </div>
  //   );
  // }
}

export default BatteryCells;
