import React, { useEffect, useState } from 'react'
import { Row, Col, Card } from "reactstrap"
import SmcLogo from "assets/img/smcLogo.png"
import {
    AWS_REGION,
    AWS_IOT_ENDPOINT,
    AWS_CUSTOM_AUTH_USERNAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
    AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
    AWS_CUSTOM_AUTH_PASSWORD,
} from "../../views/MQTTSettings";
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
function Semiconic() {
    const [tms, setTms] = useState()
    const [data, setData] = useState()
    useEffect(() => {
        main()
        // const jsonData = packet
        // if (jsonData.evc === '481') {
        //     const date = jsonData.tms.split('T')[0]
        //     const time = jsonData.tms.split('T')[1]
        //     setTms(`${time.split('+')[0]} ${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}`)
        //     const dataArray = Object.entries(jsonData.mpptData)
        //     let arr = []
        //     for (const element of dataArray) {
        //         arr.push([formatCamelCase(element[0]), element[1]])
        //     }
        //     setData(arr);
        // }
    }, [])
    function formatCamelCase(str) {
        if (str === 'kwh') {
            return 'kWh'
        }
        else {
            // Insert a space before each uppercase letter (except the first one)
            let result = str.replace(/([A-Z])/g, ' $1');
            // Capitalize the first letter of each word
            result = result.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            let resu
            if (result.includes('Batt ')) {
                resu = result.replace('Batt', 'Battery')
            }
            else if (result.includes('Ip')) {
                resu = result.replace('Ip', 'I/P')
            }
            else resu = result
            return resu.trim(); // Remove leading or trailing spaces, if any
        }
    }

    // Example usage:

    const packet = {
        "did": "AET868221073160346",
        "tms": "2024-10-09T17:07:36+0530",
        "evc": "481",
        "mpptData": {
            "luminaryFault": 1,
            "batteryFault": 0,
            "solarModuleFault": 0,
            "systemStatus": 1,
            "ledStatus": 1,
            "battVoltage": 18.23,
            "battCurrent": 0,
            "batteryPower": 0,
            "panelVoltage": 0,
            "panelCurrent": 0,
            "panelPower": 0,
            "loadIpVoltage": 20.23,
            "loadIpCurrent": 9.51,
            "loadPower": 19.9,
            "kwh": 0,
            "batteryStateOfCharge": 80,
            "batteryDepthOfDischarge": 0,
            "brightnessLevel": 100,
            "fullBrightnessHour": 8,
            "halfBrightnessHour": 4
        }
    }
    const id = 'AET868221073160346'
    let connection = null;
    async function connect_websocket() {
        return new Promise((resolve, reject) => {
            let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
                .with_clean_session(true)
                .with_client_id(new Date())
                .with_endpoint(AWS_IOT_ENDPOINT)
                .with_custom_authorizer(
                    AWS_CUSTOM_AUTH_USERNAME,
                    AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
                    AWS_CUSTOM_AUTH_PASSWORD,
                    AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
                )
                .with_keep_alive_seconds(30)
                .build();

            console.log("Connecting custom authorizer...");
            const client = new mqtt.MqttClient();

            connection = client.new_connection(config);
            connection.on("connect", (session_present) => {
                console.log("Connected", session_present);
                resolve(connection);
            });
            connection.on("interrupt", (error) => {
                console.log(`Connection interrupted: error=${error}`);
            });
            connection.on("resume", (return_code, session_present) => {
                console.log(
                    `Resumed: rc: ${return_code} existing session: ${session_present}`
                );
            });
            connection.on("disconnect", () => {
                console.log("Mqtt Disconnected");
            });
            connection.on("error", (error) => {
                reject(error);
            });
            connection.connect();
        });
    }
    async function main(val) {
        connect_websocket()
            .then((connection) => {
                connection.subscribe(
                    `adtiot/bms1.0/pub/bt/${id}`,
                    mqtt.QoS.AtLeastOnce,
                    (topic, payload, dup, qos, retain) => {
                        const decoder = new TextDecoder("utf8");
                        const message = decoder.decode(new Uint8Array(payload));
                        const jsonData = JSON.parse(message)
                        if (jsonData.evc === '481') {
                            const date = jsonData.tms.split('T')[0]
                            const time = jsonData.tms.split('T')[1]
                            setTms(`${time.split('+')[0]} ${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}`)
                            const dataArray = Object.entries(jsonData.mpptData)
                            let arr = []
                            for (const element of dataArray) {
                                arr.push([formatCamelCase(element[0]), element[1]])
                            }
                            setData(arr);
                        }
                    }
                );
            })
            .catch((reason) => {
                console.log(`Error while connecting: ${reason}`);
            });
    }
    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };
    return (
        <div>
            <Row>
                <Col xs="2" sm="2" md="2" lg="2" xl="2">
                </Col>
                <Col xs="8" sm="8" md="8" lg="8" xl="8">
                    <img src={SmcLogo} alt='...' />
                </Col>
                <Col xs="2" sm="2" md="2" lg="2" xl="2">
                    <i
                        className="nc-icon nc-button-power"
                        style={{
                            float: "right",
                            fontWeight: "900",
                            textAlign: "center",
                            fontSize: "24px",
                            cursor: "pointer",
                            margin: '5px'
                        }}
                        onClick={logOut}
                    ></i>
                </Col>
            </Row>
            <h3 style={{ textAlign: 'center' }}>MPPT Data</h3>
            {data ? <Row>
                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                    <Card className="hero-cards">
                        <div className="card-head">Time Date</div>
                        <div className="card-foot">{tms}
                        </div>
                    </Card>
                </Col>
                {data.map((val, index) =>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" key={index}>
                        <Card className="hero-cards">
                            <div className="card-head">{val[0]}</div>
                            <div className="card-foot">{val[1]}
                            </div>
                        </Card>
                    </Col>
                )}
            </Row> : <Card className="hero-cards">
                <div className="card-head">Not Connected</div>
            </Card>
            }
        </div>
    )
}

export default Semiconic