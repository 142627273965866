import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";

import { API_URL } from "variables/general";
import { bmsIdContext } from "../../views/Dashboard";
import LocationDataLogsCsv from "components/Logs/LocationDataLogsCsv";

function LocationDataLog() {
  useEffect(() => {
    handlebmsLocation()
  }, [])
  const bmsId = useContext(bmsIdContext)
  const [noOfSamples, setNoOfSamples] = useState(50)
  const [disablingButton, setDisablingButton] = useState(true)
  const [locationData, setLocationData] = useState('')
  const [showRecent, setShowRecent] = useState('Recent ')
  const [tms, setTms] = useState('')
  const getTms = (val) => {
    setTms(val.target.value)
  }
  const getDataForTms = () => {
    handlebmsLocation(tms, "TMS");
  }
  const handlebmsLocation = (val, lt) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions;
    if (lt === "LT") {
      requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          did: bmsId,
          limit: parseInt(noOfSamples),
          tms_lt: val,
        }),
      };

    } else
      if (lt === 'TMS') {
        requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            did: bmsId,
            limit: parseInt(noOfSamples),
            tms: val,
          }),
        };
      } else
        requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            did: bmsId,
            limit: parseInt(noOfSamples),
          }),
          redirect: "follow",
        };
    fetch(API_URL + "/bms/bms_location", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLocationData(result.data)
      })
      .catch((error) => console.log("error", error));
  };
  const previousClicked = (val) => {
    setShowRecent('')
    // fetchBmsValues(val, "LT");
    handlebmsLocation(val, "LT");
  };
  const nextClicked = (val) => {
    setShowRecent('')
    // fetchBmsValues(val,"TMS");
    handlebmsLocation(val, "TMS");
  };
  const getCenter = () => {
    // fetchBmsValues();
    handlebmsLocation();
    setShowRecent('Recent ')
  };
  const changeNoOfSample = (val) => {
    setNoOfSamples(val)
    setDisablingButton(false)
  };
  const FaultArray = [
    "No Fault",
    "Over Pack Voltage Fault",
    "Over Pack Voltage",
    "Under Pack Voltage Fault",
    "Over OCD1 Current",
    "Over OCD2 Current",
    "Over Charge Current",
    "Over Discharge Current",
    "Short Circuit",
    "Cell Open Wire Fault",
    "Voltage Reference Fault",
    "Voltage Regulator Fault",
    "Temperature Multiplexer Error",
    "Under Temperature Fault",
    "Load Over Temperature",
    "Oscillator Fault",
    "Watchdog Timeout Fault",
    "Under Cell Voltage Fault",
    "Over Cell Voltage Fault",
    "Open Wire Fault",
    "Over Temperature Fault",
    "Cell Unbalance Pro",
    "SCD Latch High",
    "SPI Communication Fault",
    "I2C Communication Fault",
    "MCU Reset Fault",
    "Data CRC Error Fault",
    "Data Ready Fault",
    "Charger Authentication Failed",
    "Thermal Runaway Due To Temperature",
    "Thermal Runaway Due To Voltage",
    "MOSFET Drive Voltage Fault",
    "Reserve Condition Reached",
    "Battery Empty Reached",
    "Battery Full Charged Reached"
  ]
  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#30428c",
    color: "white",
    zIndex: 1,
    padding: "5px",
  };

  const tableRowStyle = {
    fontWeight: "700",
    padding: "5px",
    borderBottom: "2px solid #c1d6c6",
  };
  if (locationData) {
    // let valueArray = [];
    // for (let i = 0; i < valueData.bmsValues.length; i++) {
    //   valueArray[i] =
    //     valueData.bmsValues[
    //     valueData.bmsValues.length - 1 - i
    //     ];
    // }
    let locationArray = [];
    for (let i = 0; i < locationData.bmsLocation.length; i++) {
      locationArray[i] =
        locationData.bmsLocation[locationData.bmsLocation.length - 1 - i];
    }
    const buttonBasics = {
      borderWidth: 0,
      borderRadius: "20px",
      backgroundColor: "#B6BDBB",
      color: "white",
      padding: "5px 15px 5px 15px",
      margin: "5px 50px",
      fontWeight: "bold",
    };
    if (locationData.bmsLocation.length > 0)
      return (
        <div>
          <div>
            <LocationDataLogsCsv batteryPackId={bmsId} />
          </div>
          <hr />

          <p>
            No Of Entries
            <b style={{ margin: "5px" }}>
              <select
                onChange={(e) => changeNoOfSample(e.target.value)}
                value={noOfSamples}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
              </select>
            </b>&ensp;
            <button
              onClick={getCenter}
              disabled={disablingButton ? "disabled" : null}
            >
              Set
            </button>

            &ensp;
            <input type="datetime-local" onChange={getTms} value={tms}></input> &ensp;
            <button
              onClick={getDataForTms}
            // disabled={disablingButton ? "disabled" : null}
            >
              Set
            </button>
          </p>

          <div className="main-margin">
            <h6 style={{ cursor: "pointer" }}>
              <button
                style={buttonBasics}
                onClick={() =>
                  previousClicked(locationData.bmsLocation[0].tms)
                }
              >
                <i class="fas fa-chevron-left"></i>
              </button>
              <a onClick={getCenter}>{showRecent}BMS Locations</a>
              <button
                style={buttonBasics}
                onClick={() =>
                  nextClicked(
                    locationData.bmsLocation[
                      locationData.bmsLocation.length - 1
                    ].tms
                  )
                }
              >
                <i class="fas fa-chevron-right"></i>
              </button>
            </h6>
          </div>
          <table
            style={{
              overflow: "scroll",
              display: "block",
              height: "80vh",
            }}
          >
            <thead style={tableHeaderStyle}>
              <tr>
                <th style={tableHeaderStyle}>S. No.</th>
                <th style={tableHeaderStyle}>Time</th>
                <th style={tableHeaderStyle}>No Of Satellites</th>
                <th style={tableHeaderStyle}>GPS Fix Status</th>
                <th style={tableHeaderStyle}>GPS Lat Dir</th>
                <th style={tableHeaderStyle}>GPS Long Dir</th>
                <th style={tableHeaderStyle}>GPS Lat Coordinate</th>
                <th style={tableHeaderStyle}>GPS Long Coordinate</th>
                <th style={tableHeaderStyle}>GPS Speed</th>
                <th style={tableHeaderStyle}>Alarm</th>
              </tr>
            </thead>
            {locationArray.map((val, i) => (
              <tbody>
                <tr>
                  <td className="text-center" style={tableRowStyle}>
                    {i + 1}.
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.tms ? (
                      <>
                        {val.tms.slice(11, 19)}&ensp;
                        {val.tms.slice(8, 10)}/{val.tms.slice(5, 7)}/
                        {val.tms.slice(0, 4)}
                      </>
                    ) : null}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.gpsNoOfSatellites}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.gpsFixStatus}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.gpsLatDir}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.gpsLongDir}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.gpsLatCoordinate}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.gpsLongCoordinate}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.gpsSpeed}
                  </td>
                  <td className="text-center" style={tableRowStyle}>
                    {val.alarm}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      );
    else return <div>Previous data not found</div>
  } else
    return (
      <div className="content">
        <div className="loadingContainer">
          <div className="ball1"></div>
          <div className="ball2"></div>
          <div className="ball3"></div>
          <div className="ball4"></div>
        </div>
      </div>
    );
}

export default LocationDataLog;
