import React from "react";
import { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { Row, Col, Container, Button } from "reactstrap";
import { API_URL } from "variables/general";
// let inputArray = [];
function AddMacAddress(props) {
  const [macAddress, setMacAddress] = useState("");
  const [macAddressToDelete, setMacAddressToDelete] = useState("");
  const [macAddressList, setMacAddressList] = useState();
  const [macAddressString, setMacAddressString] = useState();
  const [subUserList, setSubUserList] = useState("");
  const [subUserId, setSubUserId] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [inputArray, setInputArray] = useState([]);
  useEffect(() => {
    getSubUser();
  }, []);
  const getSubUser = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/manage_bms_subuser?sub_user=All", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSubUserList(result.data.users);
      })
      .catch((error) => console.log("error", error));
  };
  const MacAddress = (value) => {
    setMacAddress(value);
  };
  const incrementInputField = (second) => {
    if (macAddress.length === 17)
      setInputArray((inputArray) => [...inputArray, macAddress]);
    else alert("invalid mac address");
  };
  const removeMac = (indexToRemove) => {
    const newArray = inputArray.filter(
      (fruit, index) => index !== indexToRemove
    );
    setInputArray(newArray);
  };
  const sendMacAddress = (second) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    const mcA = macAddressString.split('\n')
    if (mcA.length > 0) {
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          subOwner: subUserId,
          macId: mcA,
          edit: "edit",
        }),
      };
      fetch(API_URL + "/bms/inventory", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          let status = result.status;
          if (status === true) alert("Update Successful");
        })
        .catch((error) => console.log("error", error));
    } else alert("Add atleast one Mac Address");
  };
  const getMacAddress = (val) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(API_URL + "/bms/inventory?sub_owner=" + val, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let status = result.status;
        if (status === true) setMacAddressList(result.data.macAllowed);
      })
      .catch((error) => console.log("error", error));
  };
  const deleteMacAddress = (val) => {
    // console.log(macAddressToDelete)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify({
        subOwner: subUserId,
        macId: macAddressToDelete,
      }),
    };
    fetch(API_URL + "/bms/inventory", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let status = result.status;
        if (status === true) alert("Update Successful");
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  const getSubUserId = (event) => {
    setSubUserId(event.value);
    getMacAddress(event.value);
    setDisabled(false);
    setMacAddressList(false)
  };
  const getMacAddressToEdit = (val) => {
    setMacAddressToDelete([val.value])
  }
  if (subUserList) {
    let subUserListOptions = [];
    subUserList.map(
      (val, i) =>
      (subUserListOptions[i] = {
        value: val.id,
        label: val.userProfile.name,
      })
    );
    let macAddressListOptions = [];
    if (macAddressList) {
      macAddressList.map(
        (val, i) =>
        (macAddressListOptions[i] = {
          value: val,
          label: val,
        })
      );
    }
    return (
      <Container style={{ marginTop: "5rem" }}>
        <Row>
          <Col>
            <div>Select Sub User</div>
            <ReactSelect
              onChange={getSubUserId}
              options={subUserListOptions}
            ></ReactSelect>
          </Col>
          <Col>
            {macAddressList ? (
              <div>
                <div>Added Mac Addresses(Select to delete)</div><ReactSelect
                  onChange={getMacAddressToEdit}
                  options={macAddressListOptions}
                ></ReactSelect>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Enter Mac Address to Add</strong>
            <br />
            <textarea
              onChange={(e) => setMacAddressString(e.target.value)}
            ></textarea>
          </Col>
          <Col>{
            macAddressToDelete ? <div> macAddressToDelete : {macAddressToDelete}
              <br />
              <button onClick={deleteMacAddress}>Delete</button>
            </div> : ''
          }
          </Col>
        </Row>

        <Button onClick={sendMacAddress} disabled={disabled}>
          Save
        </Button>
      </Container>
    );
  } else return <div>Loading..</div>;
}

export default AddMacAddress;
